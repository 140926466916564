import React from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import useRewardCenter from 'shared/screens/RewardCenter/hooks/useRewardCenter';
import styled, { css } from 'styled-components';
import ScrollContainer from 'react-indiana-drag-scroll';
import Menu from './components/RewardCenterMenu';
import RewardCenterProfile from './components/RewardCenterProfile';
import RewardCenterCategories from './components/Cards/RewardCenterCategories';
import RewardCenterOffers from './components/Cards/RewardCenterOffers';
import RewardCenterOfferDetail from './components/RewardCenterOfferDetail';
import { Spinner } from 'react-bootstrap';
import LoadingLayer from '../../components/Loading/Loading';
import { isStandalonePWA } from '../../utils/mobileSupported';

const LoadingSpinner = () => (
  <Loading>
    <Spinner animation="border" />
  </Loading>
);

const RewardCenter = props => {
  const rewardCenterHookData = useRewardCenter(props);
  const { rewardCenterAssets, showCategories, categories, offers, selectedOffer, clearFilterData } =
    rewardCenterHookData;

  return rewardCenterAssets ? (
    <>
      {selectedOffer && <RewardCenterOfferDetail {...rewardCenterHookData} />}
      <RewardCenterProfile {...rewardCenterHookData} />
      <Background src={rewardCenterAssets.REWARD_CENTER_BACKGROUND} />
      {!showCategories && (
        <BackButtonmage onClick={clearFilterData} src={rewardCenterAssets?.REWARD_CENTER_ARROW_BACK_BUTTON} />
      )}

      {categories.length === 0 && <LoadingSpinner />}
      {(categories.length !== 0 || offers.length !== 0) && (
        <RewardCenterContainer>
          <RewardCenterContent>
            {showCategories ? (
              <RewardCenterList
                count={categories?.length}
                data={categories}
                renderItem={object => <RewardCenterCategories object={object} {...rewardCenterHookData} />}
              />
            ) : (
              <RewardCenterList
                data={offers}
                count={offers?.length}
                renderItem={object => <RewardCenterOffers object={object} {...rewardCenterHookData} />}
              />
            )}
          </RewardCenterContent>
          <Menu {...rewardCenterHookData} />
        </RewardCenterContainer>
      )}
    </>
  ) : (
    <LoadingLayer loading={!rewardCenterAssets} />
  );
};

export default RewardCenter;

const RewardCenterList = ({ data, renderItem, count }) => {
  return (
    <RewardCenterCardsContainer count={count} vertical={false}>
      {data?.map(renderItem)}
    </RewardCenterCardsContainer>
  );
};

const Loading = styled.div`
  position: absolute;
  z-index: 99;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const RewardCenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const RewardCenterContent = styled.div`
  height: 66%;
  z-index: 1;

  @media only screen and (min-height: 575px) and (max-height: 650px) {
    height: 70%;
  }

  @media only screen and (min-height: 650px) and (max-height: 800px) {
    height: 76%;
  }

  @media only screen and (min-height: 800px) {
    height: 80%;
  }
`;

const Background = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
`;

const CardContainerMobileStyle = css`
  margin-top: ${isTablet ? (isStandalonePWA ? 16 : 11) : isStandalonePWA ? 5.5 : 3}rem;
`;

const RewardCenterCardsContainer = styled(ScrollContainer)`
  padding-right: 100px;
  padding-left: 100px;
  margin-top: 6rem;
  display: flex;
  gap: ${({ count }) => (count > 3 ? 2 : 4)}rem;
  z-index: 1;
  padding-bottom: 4rem;
  padding-top: 1rem;

  @media only screen and (min-height: 1080px) {
    height: 100%;
  }

  @media only screen and (min-width: 1490px) {
    gap: ${({ count }) => (count > 3 ? 2 : 4)}rem;
  }

  @media only screen and (min-width: 1670px) {
    gap: ${({ count }) => (count > 3 ? 3 : 6)}rem;
  }

  @media only screen and (min-width: 2150px) {
    gap: ${({ count }) => (count > 3 ? 4 : 6)}rem;
  }

  @media only screen and (min-width: 1900px) {
    gap: ${({ count }) => (count > 3 ? 2 : 5)}rem;
  }

  @media only screen and (max-height: 650px) {
    margin-top: 2rem;
    ${isMobile && CardContainerMobileStyle}
  }

  @media only screen and (min-height: 650px) and (max-height: 985px) {
    margin-top: 3rem;
    ${isMobile && CardContainerMobileStyle}
  }

  ${isMobile && CardContainerMobileStyle}
`;

const BackButtonmage = styled.img`
  position: absolute;
  cursor: pointer;
  height: 3.6rem;
  width: 3.6rem;
  top: ${isMobile ? (isTablet ? (isStandalonePWA ? 12 : 9) : isStandalonePWA ? 7.5 : 7) : 6}rem;
  left: 2rem;
  z-index: 2;
`;

